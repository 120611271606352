.mb-menu-nav{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #0B0B0B;
}

@media screen and (max-width: 1024px){
    .mb-menu-nav{
        width: 350px;
    }
}

@media screen and (max-width: 768px){
    .mb-menu-nav{
        width: 300px;
    }
}