.dt-menu-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100vw;
}

@media screen and (max-width: 1024px){
    .dt-menu-bar{
        display: none;
    }
}

@media screen and (max-width: 768px){
    .dt-menu-bar{
        display: none;
    }
}