.dt-menu-bar-logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;
}

.dt-menu-bar-logo__sillhoute{
    height: 40px;
    width: 40px;
}

.dt-menu-bar-logo__logo{
    width: 180px;
    height: 80px;
    object-fit: cover;
}