.mobile-menu{
    position: absolute;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100%;
    z-index: 1;
    transition: left 0.5s;
}

.mobile-menu--active{
    left: 0;
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    transition: left 0.5s, backdrop-filter 0.5s ease 0.5s, background-color 0.5s ease 0.5s;
}