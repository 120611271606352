.mb-menu-link__content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 20px;
}

.mb-menu-link__indicator{
    height: 5px;
    width: 100%;
}

.mb-menu-link--active{
    font-weight: 500;
}

.mb-menu-link--active .mb-menu-link__indicator{
    background-color: #FFFFFF;
}

a{
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 100;
}

@media screen and (max-width: 1024px){
    .mb-menu-link{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 175px;
    }

    .mb-menu-link:not(:last-child){
        margin-bottom: 40px;
    }

    .mb-menu-link svg{
        height: 30px;
        width: 30px;
    }

    .mb-menu-link__indicator{
        margin-top: 10px;
    }

    a{
        font-size: 24px;
    }
}

@media screen and (max-width: 768px){

    .mb-menu-link{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 150px;
    }

    .mb-menu-link:not(:last-child){
        margin-bottom: 20px;
    }

    .mb-menu-link svg{
        height: 25px;
        width: 25px;
    }

    .mb-menu-link__indicator{
        margin-top: 10px;
    }

    a{
        font-size: 20px;
    }

}