.menu-bar-link__link{
    color: #FFFFFF;
    font-weight: 100;
    text-decoration: none;
    transition: font-size 0.5s;
}

.menu-bar-link__link:hover{
    font-size: 20px;
    font-weight: 700;
}

.menu-bar-link__indicator{
    height: 5px;
    width: 100%;
    margin-top: 5px;
}

.menu-bar-link__link--active{
    font-weight: 700;
}

.menu-bar-link__link--active + .menu-bar-link__indicator{
    background-color: #FFFFFF;
    transition: background-color 0.5s;
}