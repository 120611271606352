*{
    box-sizing: border-box;
}

html, body{
    background-color: #0B0B0B;
    color: #FFFFFF;
    font-family: 'Outfit', sans-serif;
    margin: 0;
    padding: 0;
}