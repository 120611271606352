.mb-menu-nav-header{
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 10px;
}

@media screen and (max-width: 1024px){
    .mb-menu-nav-header__close{
        font-size: 36px;
    }

    .mb-menu-nav-header__logo{
        height: 80px;
        width: 200px;
        object-fit: cover;
        margin-left: 10px;
    }
}

@media screen and (max-width: 768px){
    .mb-menu-nav-header__close{
        font-size: 28px;
    }

    .mb-menu-nav-header__logo{
        height: 60px;
        width: 150px;
        object-fit: cover;
        margin-left: 10px;
    }
}
