.coming-soon{
    width: 100%;
    height: 100%;
}

.coming-soon__content{
    position: absolute;
    top: 45%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 900px;
    animation: slide-in 1s ease;
}

.coming-soon__title{
    text-align: center;
    font-size: 100px;
    font-weight: 100;
}

.coming-soon__message{
    font-weight: 100;
    text-align: justify;
    text-align-last: center;
    margin: auto;
    line-height: 50px;
}


@media screen and (max-width: 1024px){

    .coming-soon__content{
        width: 700px;
    }

    .coming-soon__title{
        font-size: 75px;
    }
}

@media screen and (max-width: 768px){

    .coming-soon__content{
        width: 350px;
    }

    .coming-soon__title{
        font-size: 50px;
    }

    .coming-soon__message{
        line-height: 40px;
    }
}


@keyframes slide-in {
    0%{
        top: 200%;
    }

    50%{
        top: 30%;
    }

    100%{
        top: 45%;
    }
}