@media screen and (max-width: 1024px){
    .mb-menu-toggle__button{
        font-size: 36px;
    }
}

@media screen and (max-width: 768px){
    .mb-menu-toggle__button{
        font-size: 28px;
    }
}