.mb-menu-bar{
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.mb-menu-bar__logo{
    object-fit: cover;
}

@media screen and (max-width: 1024px){
     .mb-menu-bar{
        display: flex;
        height: 80px;
    }

    .mb-menu-bar__hamburger, .mb-menu-bar__opensea{
        font-size: 36px;
    }

    .mb-menu-bar__logo{
        height: 80px;
        width: 200px;
    }
}

@media screen and (max-width: 768px){
    .mb-menu-bar{
        display: flex;
        height: 60px;
    }

    .mb-menu-bar__hamburger, .mb-menu-bar__opensea{
        font-size: 28px;
    }

    .mb-menu-bar__logo{
        height: 60px;
        width: 150px;
    }
}