.mb-menu-nav-social-links{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

@media screen and (max-width: 1024px){
    .mb-menu-nav-social-links svg{
        height: 38px;
        width: 38px;
    }
}

@media screen and (max-width: 768px){
    .mb-menu-nav-social-links svg{
        height: 34px;
        width: 34px;
    }
}