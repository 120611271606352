.dt-menu-bar-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 40px;
    margin-right: 25px;
}

.dt-menu-bar-links__opensea{
    color: #FFFFFF;
    font-size: 36px;
    transition: font-size 0.5s;
}

.dt-menu-bar-links__opensea:hover{
    font-size: 44px;
}