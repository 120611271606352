.opensea-icon{
    color: #FFFFFF;
}

@media screen and (max-width: 1024px){
    .opensea-icon{
        font-size: 36px;
    }
}

@media screen and (max-width: 768px){
    .opensea-icon{
        font-size: 28px;
    }
}