.content{
    width: 100vw;
    max-width: 100vw;
    height: calc(100vh - 80px);
    overflow-y: auto;
    scroll-behavior: smooth;
}

@media screen and (max-width: 1024px){
    .content{
        height: calc(100% - 80px);
    }
}

@media screen and (max-width: 768px){
    .content{
        height: calc(100% - 60px);
    }
}