.mb-menu-nav-links{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1024px){
    .mb-menu-nav-links{
        margin-top: 50px;
    }
}

@media screen and (max-width: 768px){
    .mb-menu-nav-links{
        margin-top: 35px;
    }
}